@keyframes blinking {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}
.custom-skeleton-box {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    border-radius: 1px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: blinking 1s ease-in-out infinite;
}

.animated-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e6e4e4;
}